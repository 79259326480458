<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

            ****      ****    **      **  ******    ****      ****    ******  
          **    **  **    **  ****    **    **    **    **  **    **    **    
          **        **    **  **  **  **    **    ********  **          **    
          **    **  **    **  **    ****    **    **    **  **    **    **    
            ****      ****    **      **    **    **    **    ****      **    

  *********************************************************************************-->
  <!-- beautify preserve:end -->

  


    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold">{{this.$store.getters.conference.ticketTitle}}</h2>

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <v-btn
        color="#c00000"
        class="rm-open-popup"
        raised
        x-large
      >
        <!-- <span class="white--text text--darken-1 font-weight-bold">Newsletter Abonnieren</span> -->
        <span class="white--text text--darken-1 font-weight-bold">Newsletter Abonnieren</span>
      </v-btn>
    </v-container>


</template>

<script>
export default {
  name: "Signup",

  mounted() {
    // add rapid mail script
    let rapidMailScript = document.createElement('script');
   rapidMailScript.setAttribute('src', 'https://t03488d2b.emailsys2a.net/form/150/3723/bc13b36dd9/popup.js?_g=1706191149'); //Newsletter Anmeldung
//rapidMailScript.setAttribute('src', 'https://t03488d2b.emailsys2a.net/form/150/8773/36d418157f/popup.js?_g=1683641510'); //Konferenz Anmeldung
    rapidMailScript.setAttribute('async', 'async');
    document.head.appendChild(rapidMailScript);
  },
  };
</script>

