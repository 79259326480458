<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

            ****    ******      ****    **    **  ******  
          **    **  **    **  **    **  **    **    **    
          ********  ********  **    **  **    **    **    
          **    **  **    **  **    **  **    **    **    
          **    **  ******      ****      ****      **    

  *********************************************************************************-->
  <!-- beautify preserve:end -->

  <section id="about" class="grey lighten-3">
    <div class="py-12"></div>

    <v-container class="text-center">
      <!--
      <h2 class="display-2 font-weight-bold mb-3">Über den Agile Circle</h2>

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>
      -->

      <v-responsive class="mx-auto title font-weight-light mb-8 text-center" max-width="720">
        <vue-markdown>{{$store.getters.conference.aboutText}}</vue-markdown>
      </v-responsive>

      <social-media-bar class="grey lighten-3"></social-media-bar>
    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
import SocialMediaBar from "@/components/SocialMediaBar.vue";
import VueMarkdown from 'vue-markdown'

export default {
  name: "About",

  components: {
    SocialMediaBar,
    VueMarkdown,
  },
};
</script>
<style lang="scss">
  #about h2 {
    margin-bottom: 48px;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.125rem;
    letter-spacing: normal;
    font-family: "Roboto", sans-serif;
  }
</style>