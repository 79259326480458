<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

          ******  ********    ****    **      **  
            **    **        **    **  ****  ****  
            **    ********  ********  **  **  **  
            **    **        **    **  **      **  
            **    ********  **    **  **      **  

  *********************************************************************************-->
  <!-- beautify preserve:end -->

  <section id="team" class="grey lighten-3">
    <div class="py-12"></div>

    <v-container>

      <h2 class="display-2 font-weight-bold mb-3 text-center">Agile Circle Presenters Club</h2>

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <vue-markdown v-if="presentersClubTeaserText" class="title font-weight-light mb-5 text-center">{{presentersClubTeaserText}}</vue-markdown>

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <section>

        <v-row>
          <v-col v-for="(host, i) in hosts" :key="i" align="center" justify="center" cols="3">

            <v-avatar v-if="host.img_url" class="elevation-12 mb-6" size="128">
              <v-img class="avatar" :src="host.img_url"></v-img>
            </v-avatar>
            <div class="headline font-weight-bold mb-1" v-text="host.name"></div>
            <v-btn x-small v-for="({ image, link }, i) in host.social" :key="i" :href="link" target="__blank"
              class="pa-0 mr-2 " icon>
              <v-img :src="image" height=20 width=20 contain class="pa-0 ma-0"></v-img>
            </v-btn>
            <a v-if="host.company_link" target="_blank" :href="host.company_link" v-text="host.company_name"></a>

          </v-col>
        </v-row>



      </section>



      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>





      <h2 class="display-2 font-weight-bold mb-3 text-center">{{this.$store.getters.conference.teamTitle}}</h2>

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <v-responsive class="mx-auto title font-weight-light mb-8 text-center" max-width="720">
        Kein Ergebnis ohne Team! Hinter dem Agile Circle stehen viele Persönlichkeiten, die maßgeblich an der
        Entstehung des Agile Circle mitgewirkt haben. Jedem Teammitglied wurden folgende Fragen gestellt:
        <br />
        <br />
        <b>
          Warum Agile?
          <br />Warum Agile Circle?
          <br />
        </b>
        <br />Folgend die Antworten:
      </v-responsive>

      <v-row>
        <v-col v-for="({ videourl, text, title }, i) in team" :key="i" cols="12" md="4">
          <div class="embed-responsive embed-responsive-16by9">
            <youtube host="https://www.youtube-nocookie.com" class="embed-responsive-item" player-width="100%"
              player-height="100%" :video-id="videourl"></youtube>
          </div>
          <!--               <v-img
                :src="src"
                class="mb-4"
                height="275"
                max-width="100%"
          ></v-img>-->

          <h3 class="font-weight-black mb-4 text-uppercase" v-text="title"></h3>

          <vue-markdown class="title font-weight-light mb-5">{{text}}</vue-markdown>

          <!--               <v-btn
                class="ml-n4 font-weight-black"
                text
              >
                Continue Reading
          </v-btn>-->
        </v-col>
      </v-row>
    </v-container>

    <v-responsive class="mx-auto mb-12" width="56">
    </v-responsive>

<!--     <v-row align="center" justify="center">
      <book-ticket-button></book-ticket-button>
    </v-row> -->

    <div class="py-12"></div>
  </section>
</template>

<script>
  //import BookTicketButton from '@/components/BookTicketButton.vue';
  import VueMarkdown from 'vue-markdown';

  export default {
    name: "Team",

    components: {
      //BookTicketButton,
      VueMarkdown,
    },

    data: () => ({
      team: [],
      hosts: [],
      presentersClubTeaserText: '',
    }),

    created() {
      this.buildTeam();

    },

    methods: {
      buildTeam() {
        const team = this.$store.getters.conference.team;


        team.forEach(cur => {
          this.team.push({
            videourl: cur.youtubeId,
            title: cur.title,
            text: cur.text
          });
        });

        const hosts = this.$store.getters.hosts;

        hosts.forEach(host => {
          const hostToAdd = {
            name: host.name,
            experte: host.title,
            cv: host.description,
            img_url: this.buildPath(host.picture.url),
            company_name: host.companyName,
            company_link: host.companyLink,
            social: [],
            presentersclub: host.presentersclub,
          };

          host.social.forEach(curSocial => {
            hostToAdd.social.push({
              name: curSocial.name,
              image: this.buildPath(curSocial.image.url),
              link: curSocial.link
            });
          });
          if (hostToAdd.presentersclub) {
            this.hosts.push(hostToAdd);
          }

        });
        this.presentersClubTeaserText = this.$store.getters.conference.presentersClubTeaserText;
      }
    }
  };
</script>
<style lang="scss">
  #program #abstract h3 {
    margin-bottom: 12px;
  }

  .avatar {
    filter: gray;
    /* IE6-9 */
    -webkit-filter: grayscale(1);
    /* Google Chrome, Safari 6+ & Opera 15+ */
    filter: grayscale(1);
    /* Microsoft Edge and Firefox 35+ */

  }

  .avatar:hover {
    -webkit-filter: grayscale(0);
    filter: none;
  }
</style>