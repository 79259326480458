<template>
  <!--   <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn color="#c00000" target="_blank" :href="url" raised x-large v-on="on"
        :disabled="!url">
        <span class="white--text text--darken-1 font-weight-bold">
          Ticket buchen
        </span>
      </v-btn>
    </template>
    <span>{{tooltipText}}</span>
  </v-tooltip> -->


  <v-dialog  v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">


    <template v-slot:activator="{ on }">
      <v-btn color="#c00000" raised x-large v-on="on">
        <span class="white--text text--darken-1 font-weight-bold">
          Ticket Buchen
        </span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar light color="#C00000">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title class="white--text">Ticket buchen für Agile Circle CONFERENCE 2022 - 21. Juli 2021
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn light color="white" text @click="dialog = false">Schliessen</v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container height="100%" width="100%">
        <v-row height="100%" justify="center">

          <v-col>
            <v-card height="100%" flat>
              


              <iframe title="Ticketshop"
                src="https://AgileCircleConference2022.xing-events.com/AgileCircleConference2022.html?viewType=iframe&distributionChannel=CHANNEL_IFRAME&language=de&resizeIFrame=true"
                frameborder="0" width="100%" id="_amiandoIFrame4763145">
                <p>Diese Seite benötigt die Unterstützung von Frames durch Ihren Browser. Bitte nutzen Sie einen
                  Browser, der die Darstellung von Frames unterstützt, damit das Ticketvorverkaufs-Modul angezeigt
                  werden kann.</p>
              </iframe>
            </v-card>
          </v-col>
         

        </v-row>
      </v-container>
    </v-card>


  </v-dialog>



</template>



<script>
  export default {
    name: "BookTicketButton",

    data: () => ({
      url: undefined,
      tooltipText: "",
      dialog: false
    }),

  };
</script>