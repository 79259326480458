<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

        **          ****      ******    ****      ******  
        **        **    **  **        **    **  **        
        **        **    **  **  ****  **    **    ****    
        **        **    **  **    **  **    **        **  
        ********    ****      ****      ****    ******    

        ********************************************************************************* -->
  <!-- beautify preserve:end -->

  <section id="logos" v-bind:style="{ 'min-height' : minHeight + 'em'}">
    <v-container fill-height>
      <v-row class="mx-auto justify-center align-center d-flex flex-column flex-md-row">
        <v-col v-for="{name, image, link} of logos" :key="name" cols="2" md="3">
          <a :href="link" target="_blank">
            <div class="text-center ">
              <v-img :src="image" class="v-bw-image"></v-img>
            </div>
          </a>
        </v-col>
      </v-row>
    </v-container>

  </section>
</template>

<script>
  export default {
    name: 'Logos',

    data: () => ({
      logos: [],
      minHeight: 0,
    }),

    created() {
      this.buildLogos();
    },

    mounted() {
      window.addEventListener('resize', () => {
        this.setMinHeight();
      });
    },

    methods: {
      buildLogos() {
        this.setMinHeight();
        this.$store.getters.conference.sponsors.forEach(sponsor => {
          this.logos.push({
            name: sponsor.name,
            image: this.buildPath(sponsor.image.url),
            link: sponsor.link
          });
        });
      },

      setMinHeight() {
        this.minHeight = Math.round((this.$store.getters.conference.sponsors.length + 1) / 4) * (window.innerWidth / 95);
      }
    }
  }
</script>