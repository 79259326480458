import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/es5/locale/de';
import VueScrollactive from 'vue-scrollactive';
//import VueYouTubeEmbed from 'vue-youtube-embed'
import VueYoutube from 'vue-youtube'
 
Vue.use(VueScrollactive);

Vue.use(Vuetify);

Vue.use(VueYoutube);

//Vue.use(VueYouTubeEmbed, {"player-vars":{"origin":"https://www.agilecircle.org"}})


export default new Vuetify({
    lang: {
      locales: { de },
      current: 'de',
    },
});
