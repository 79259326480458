<template>
  <section>
    <div v-for="(host, i) in hosts" :key="i">
      <v-row align="center" justify="center">
        <v-spacer> </v-spacer>
        <v-col v-if="host.img_url" cols="10" align="center" justify="center">
          <v-avatar class="elevation-12 mb-6" size="128">
            <v-img :src="host.img_url"></v-img>
          </v-avatar>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10" align="center" justify="center">
          <div class="caption font-regular" v-text="host.experte"></div>
          <div class="headline font-weight-bold mb-1" v-text="host.name"></div>
          <v-btn x-small v-for="({ image, link }, i) in host.social" :key="i" :href="link" target="__blank"
            class="pa-0 mr-2 " icon>
            <v-img :src="image" height=20 width=20 contain class="pa-0 ma-0"></v-img>
          </v-btn>
          <a v-if="host.company_link" target="_blank" :href="host.company_link" v-text="host.company_name"></a>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>

      <v-row>
        <v-spacer></v-spacer>
        <v-col cols="10" align="center" justify="center" class="pb-0">
          <vue-markdown v-if="host.cv" class="title font-weight-light mb-5">{{host.cv}}</vue-markdown>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </div>


  </section>
</template>

<script>
  import VueMarkdown from 'vue-markdown';

  export default {
    name: "HostsOverview",

    components: {
      VueMarkdown
    },

    props: ['hosts'],

  };
</script>