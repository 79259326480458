import './globals'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import Axios from './axios/basicAxios'


Vue.config.productionTip = false;
Vue.prototype.$http = Axios;

Vue.mixin({
  methods: {
    buildPath: str => str ? `${global.FILES_BASE_URL}${str}` : undefined
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
