<template>
          <!-- beautify preserve:start -->
        <!-- ****************************************************************************

          **    **  ********  ******      ****    
          **    **  **        **    **  **    **  
          ********  ********  ******    **    **  
          **    **  **        **  **    **    **  
          **    **  ********  **    **    ****    

       ********************************************************************************* -->
        <!-- beautify preserve:end -->

        <section id="home">
          <v-row no-gutters>
            <!-- <v-img :min-height="'calc(60vh - ' + $vuetify.application.top + 'px)'" -->
            <v-img min-height="100vh" :max-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
              src='https://directus.agilecircle.org/assets/8be7f419-ecaa-4b08-809f-367709bcc2d4?format=auto'>
              <v-theme-provider dark>
                <v-container  fill-height>
                  <v-row class="white--text pa-12 ma-12 align-self-center justify-self-center mx-auto" justify="center">
                    <v-col class=" text-center" cols="12" tag="h1" >

                      <span v-if="!$vuetify.breakpoint.mdAndDown" class="font-weight-light"
                        :class="[$vuetify.breakpoint.mdAndDown ? 'display-1' : 'display-2']" >
                        {{hero.subtitle}}
                      </span>
                      <br>
                      <br>
                      <span :class="[$vuetify.breakpoint.mdAndDown ? 'display-3': 'display-4']"
                        class="font-weight-black">
                        {{hero.title}}
                      </span>
                      <br>
                      <br>
                      <span v-if="true" class="font-weight-light"
                        :class="[$vuetify.breakpoint.mdAndDown ? 'display-1' : 'display-2']">
                        {{hero.date}}
                      </span>
                    </v-col>
                    <v-btn class="white--text justify-self-center align-self-end" fab outlined @click="$emit('go-to-teaser')">
                      <v-icon>mdi-chevron-double-down</v-icon>
                    </v-btn>
                  </v-row>
                </v-container>
              </v-theme-provider>
            </v-img>
          </v-row>
        </section>
</template>

<script>
  import { formatDateRange } from '../utils/dateutils';
  export default {
    name: 'Hero',

    data: () => ({
      hero: {},
    }),

    created () {
      this.loadHero();
    },

    methods: {
      loadHero () {
        const conf = this.$store.getters.conference;

        this.hero = {
          title: conf.title,
          subtitle: conf.subtitle,
          date: conf.from != null ? formatDateRange(conf.from, conf.to, true) : "Herbst 2021",
          coverImage: this.buildPath(conf.coverImage.url),
        }
      },
    }
  }
</script>
