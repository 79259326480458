<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

          ******  ********    ****      ******  ********  ******    
            **    **        **    **  **        **        **    **  
            **    ********  ********    ****    ********  ******    
            **    **        **    **        **  **        **  **    
            **    ********  **    **  ******    ********  **    **  

        ********************************************************************************* -->
  <!-- beautify preserve:end -->

  <section id="teaser">
    <div class="py-12"></div>

    <v-container class="text-center">
      <!--
            <h1 class="display-2 font-weight-bold mb-3">Agile Circle ONLINE - 28. Mai 2020</h1>

            <v-responsive class="mx-auto mb-8" width="56">
            </v-responsive>
            -->

      <v-responsive class="mx-auto title font-weight-light mb-8" max-width="1000" >
        <vue-markdown>{{$store.getters.conference.teaserText}}</vue-markdown>

      </v-responsive>

      <div v-if="$store.getters.conference.call4IdeasVideoId" class="embed-responsive embed-responsive-16by9">
        <youtube host="https://www.youtube-nocookie.com" class="embed-responsive-item" player-width="84%"
          player-height="100%" :video-id="$store.getters.conference.call4IdeasVideoId">
        </youtube>
      </div>

      <v-responsive v-if="$store.getters.conference.call4IdeasText!=''" class="mx-auto title font-weight-light mb-8" max-width="1200">

      <v-responsive  class="mx-auto mb-12" width="56"> 
      </v-responsive>

        <vue-markdown>{{$store.getters.conference.call4IdeasText}}</vue-markdown>

      </v-responsive>

      <!--
            <v-avatar class="elevation-12 mb-12" size="128">
              <v-img src="https://www.seqis.com/assets/images/2/Weichselberger_2_quard-4d0bc8de.jpg"></v-img>
            </v-avatar>
            -->
      <div></div>


    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
  import VueMarkdown from 'vue-markdown'

  export default {
    name: 'Teaser',

    components: {
      VueMarkdown,
    },

    data: () => ({

    }),
  }
</script>
<style lang="scss">
  #teaser h1 {
    margin-bottom: 48px;
    font-weight: 700;
    font-size: 3rem;
    line-height: 3.125rem;
    letter-spacing: normal;
    font-family: "Roboto", sans-serif;
  }
</style>