import axios from 'axios';

const ax = axios.create({
    baseURL: global.STRAPI_BASE_URL,
    headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      }
});

export default ax;
