import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    conference: undefined,
    hosts: undefined,
    retrospectives: undefined,
  },
  mutations: {
    setConference(state, conference) {
      state.conference = conference;
    },
    setHosts(state, hosts) {
      state.hosts = hosts;
    },
    setRetrospectives(state, retrospectives) {
      state.retrospectives = retrospectives;
    }

  },
  actions: {
    async loadConference(context, id) {
      try {
        let conf;
        if (id) {
          conf = (await Vue.prototype.$http.get(`/conferences/${id}`)).data;
        } else {
          conf = (await Vue.prototype.$http.get('/active-conference')).data[0].conference;
        }
        context.commit('setConference', conf);
      } catch(err) {
        console.error(`Could not read conference`);
        context.commit('setConference', {});
      }
    },
    async loadHosts(context, id) {
      try {
        let hosts;
        
          hosts = (await Vue.prototype.$http.get(`/hosts`)).data;
        
        context.commit('setHosts', hosts);
      } catch(err) {
        console.error(`Could not read hosts`);
        context.commit('setHosts', {});
      }
    },
    async loadRetrospectives(context, id) {
      try {
        let retrospectives;
        
          retrospectives = (await Vue.prototype.$http.get(`/retrospectives`)).data;
        
        context.commit('setRetrospectives', retrospectives);
      } catch(err) {
        console.error(`Could not read retrospectives`);
        context.commit('setRetrospectives', {});
      }
    },

  },
  modules: {},
  getters: {
    conference: state => state.conference,
    hosts: state => state.hosts,
    retrospectives: state => state.retrospectives,
  }
})
