<template>
  <v-row>
    <v-col v-for="({ icon, title, text, videoid }, i) in features" :key="i" cols="12" md="4" class="d-flex">
      <v-card v-if="title != '' && videoid != ''" class="py-8 px-4 text-center flex-grow-1" color="grey lighten-5" flat>
        <v-theme-provider dark>
          <div>
            <v-avatar color="#c00000" size="88">
              <v-img :src="icon"></v-img>
            </v-avatar>
          </div>
        </v-theme-provider>

        <v-card-title class="justify-center font-weight-black text-uppercase" v-text="title"></v-card-title>

        <div v-if="videoid" class="embed-responsive embed-responsive-16by9">
          <youtube host="https://www.youtube-nocookie.com"  class="embed-responsive-item" player-width="100%"
            player-height="100%" :video-id="videoid">
          </youtube>
        </div>
        <div v-else>
          <v-card-text class="subtitle-1">
            <vue-markdown class="title font-weight-light mb-5">{{text}}</vue-markdown>
          </v-card-text>
        </div>

      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import VueMarkdown from 'vue-markdown';
  export default {
    name: "FeatureRow",
    props: ['features'],
    components: {
      VueMarkdown
    }
  };
</script>