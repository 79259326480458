<template>
  <v-card flat tile width="100%" :color="color" class="text-center">
    <v-card-text>
      <v-btn v-for="{ name, image, link } in social" :key="name" target="_blank" :href="link" class="mx-4" icon>
        <v-img :src="image" height="32" width="32"></v-img>
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import youtube from "@/assets/youtube.png";
import twitter from "@/assets/Twitter_bird_logo_2012.svg.png";
import linkedin from "@/assets/LI-In-Bug.png";
import xing from "@/assets/XNG_Sharebutton_v01-7d06f36109c803c7a79f5a5c597f1fb8c8ff4d310d74ee8f6b31a56b6d6c3eea.png";
import instagram from "@/assets/glyph-logo_May2016.png";

export default {
  name: "SocialMediaBar",

  props: ['color'],
  data: () => ({
    social: [
      {
        name: "Youtube",
        image: youtube,
        link:
          "https://www.youtube.com/channel/UCX2cm6EigOv-63mZ6SLcwog?view_as=subscriber",
      },
      {
        name: "Xing",
        image: xing,
        link:
          "https://www.xing.com/communities/groups/agile-circle-016e-1126508/about",
      },
      {
        name: "LinkedIn",
        image: linkedin,
        link: "https://www.linkedin.com/showcase/42710188/admin/",
      },
      {
        name: "Instagram",
        image: instagram,
        link: "https://www.instagram.com/agilecircle/",
      },
    ],
  }),
};
</script>