<template>
<div>
  <div class="spinner" v-if="loading"></div>
  <v-app>
    <v-app-bar app color="white" height="100" class="pa-0 ma-0">
      <v-tooltip v-if="logo" light bottom>
        <template v-slot:activator="{ on }">
          <v-img v-on="on" @click="goToHome()" contain :src="logo"
            position="left top" max-height="90">
          </v-img>
        </template>
        <span>Home</span>
      </v-tooltip>
      <v-spacer></v-spacer>

      <scrollactive class="d-none d-md-flex my-nav" v-on:itemchanged="onItemChanged" active-class="v-tab--active"
        :offset="80" :duration="400" bezier-easing-value=".5,0,.35,1">
        <v-tabs v-model="tab" right color="#C00000" slider-color="#C00000" optional>

          <v-tab to="/teaser#teaser" class="scrollactive-item">{{menuItems.main}}</v-tab>
          <v-tab to="/tickets#tickets" class="scrollactive-item">{{menuItems.tickets}}</v-tab>
         <!-- <v-tab to="/program#program" class="scrollactive-item">{{menuItems.program}}</v-tab> -->
          <v-tab to="/about#about" class="scrollactive-item">{{menuItems.about}}</v-tab>
          <v-tab to="/retro#retro" class="scrollactive-item">Rückblicke</v-tab>
          <v-tab to="/team#team" class="scrollactive-item">{{menuItems.team}}</v-tab>
          <v-tab to="/contact#contact" class="scrollactive-item">{{menuItems.contact}}</v-tab>
        </v-tabs>
      </scrollactive>
      <v-app-bar-nav-icon class="d-flex d-md-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

    </v-app-bar>
    <v-navigation-drawer right class="d-flex d-md-none" v-model="drawer" fixed temporary clipped>

      <v-list nav>
        <v-list-item-group v-model="group" color="#C00000" active-class="#C00000 text--accent-4">
          <v-list-item to="/">
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item>

          <v-list-item to="/teaser#teaser">
            <v-list-item-title>{{menuItems.main}}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/tickets#tickets">
            <v-list-item-title>{{menuItems.tickets}}</v-list-item-title>
          </v-list-item> 

<!--          <v-list-item to="/program#program">
            <v-list-item-title>{{menuItems.program}}</v-list-item-title>
          </v-list-item> -->

          <v-list-item to="/about#about">
            <v-list-item-title>{{menuItems.about}}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/retro#retro">
            <v-list-item-title>Rückblicke</v-list-item-title>
          </v-list-item>

          <v-list-item to="/team#team">
            <v-list-item-title>{{menuItems.team}}</v-list-item-title>
          </v-list-item>

          <v-list-item to="/contact#contact">
            <v-list-item-title>{{menuItems.contact}}</v-list-item-title>
          </v-list-item>

        </v-list-item-group>
      </v-list>

    </v-navigation-drawer>
    
    <v-main>
      <router-view @goTo="goTo" @go-to-teaser="goToTeaser" v-if="!loading" />
    </v-main>
    <v-footer v-if="!loading" class="justify-center" color="#292929" height="1500">
      <my-footer />
    </v-footer>
    <update-available-bar></update-available-bar>
  </v-app>
  </div>
</template>

<script>
  import UpdateAvailableBar from '@/components/UpdateAvailableBar.vue'
  import MyFooter from '@/components/Footer';

  export default {
    name: 'App',

    components: {
      UpdateAvailableBar,
      MyFooter,
    },

    data: () => ({
      menuItems: {},
      tab: null,
      drawer: false,
      group: null,
      logo: null,
      loading: false,
    }),

    async created() {
      this.loading = true;
      await this.loadConference();
      await this.loadHosts();
      await this.loadRetrospectives();
      this.loading = false;
    },

    watch: {
      group() {
        this.drawer = false
      },

    
    },
    methods: {
      async loadConference() {
        await this.$store.dispatch('loadConference');
        const conf = this.$store.getters.conference;
        this.logo = this.buildPath(conf.logo.url);
        this.menuItems = conf.menuItems;
      },
      async loadHosts() {
        await this.$store.dispatch('loadHosts');
        const hosts = this.$store.getters.hosts;
      },
      async loadRetrospectives() {
        await this.$store.dispatch('loadRetrospectives');
        const retrospectives = this.$store.getters.retrospectives;
      },

      onItemChanged(event, currentItem) {
        let section = null;
        if (currentItem != undefined) {

          section = "/" + currentItem.href.split("/")[3].replace('#', '');

        }
        this.tab = section;
      },

      goToHome() {
        if (this.$route.path != '/') {
          this.$router.push('/');
        }
        this.$vuetify.goTo('#home');
      },

      goToTeaser() {
        if (this.$route.path != '/teaser') {
          this.$router.push('/teaser');
        }
        this.$vuetify.goTo('#teaser', {offset: -20});
      },

      goTo(section) {
        this.$vuetify.goTo(section, {offset: -20});
      }
    },

  };
</script>
  <style>
    .spinner {
      width: 40px;
      height: 40px;
      background-color: #333;

      margin: 100px auto;
      -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
      animation: sk-rotateplane 1.2s infinite ease-in-out;
    }

    @-webkit-keyframes sk-rotateplane {
      0% {
        -webkit-transform: perspective(120px)
      }

      50% {
        -webkit-transform: perspective(120px) rotateY(180deg)
      }

      100% {
        -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg)
      }
    }

    @keyframes sk-rotateplane {
      0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
      }

      50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
        -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
      }

      100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      }
    }

    .spinner,
    [v-cloak]>* {
      display: none
    }

  
    .spinner {
      display: block;
      background-color: #C00000;
    }

  </style>