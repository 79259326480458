<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

            ****      ****    **      **  ******    ****      ****    ******  
          **    **  **    **  ****    **    **    **    **  **    **    **    
          **        **    **  **  **  **    **    ********  **          **    
          **    **  **    **  **    ****    **    **    **  **    **    **    
            ****      ****    **      **    **    **    **    ****      **    

  *********************************************************************************-->
  <!-- beautify preserve:end -->

  <v-sheet id="contact" color="#333333" dark tag="section" tile>
    <div class="py-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold">{{this.$store.getters.conference.contactTitle}}</h2>

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <v-btn
        color="#c00000"
        class="rm-open-popup"
        raised
        x-large
      >
        <span class="white--text text--darken-1 font-weight-bold">Newsletter abonnieren</span>
      </v-btn>
    </v-container>
    <div class="py-12"></div>
  </v-sheet>
</template>

<script>
export default {
  name: "Contact",

  mounted() {
    // add rapid mail script
    //let rapidMailScript = document.createElement('script');
    //rapidMailScript.setAttribute('src', 'https://t03488d2b.emailsys2a.net/form/150/8299/46d2c9bb9c/popup.js?_g=1676069462');
    //rapidMailScript.setAttribute('async', 'async');
    //document.head.appendChild(rapidMailScript);
  },
};
</script>

