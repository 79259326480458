import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/es5/services/goto'
import Main from '@/views/Main.vue'
import NotFound from '@/views/NotFound.vue'
//import About from '../views/About.vue'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Main,
    alias: ['/teaser', '/tickets', '/program', '/about', '/retro', '/team', '/contact', '/downloadsACO2021']
  },
  /*
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about"  '../views/About.vue')
  },
  */
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior: (to, from, savedPosition) => {
    if (document.documentElement.clientWidth >= 960) {
      return;
    }
    let scrollTo = 0;
    if (to.path !== '/') {
      scrollTo = to.path.replace('/', '#');
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }
    return goTo(scrollTo, {offset: 40});
  },
  routes
})

export default router
