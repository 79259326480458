<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

        ******      ****    **      **  **      **  **          ****      ****    ******      ******  
        **    **  **    **  **  **  **  ****    **  **        **    **  **    **  **    **  **        
        **    **  **    **  **  **  **  **  **  **  **        **    **  ********  **    **    ****    
        **    **  **    **  **  **  **  **    ****  **        **    **  **    **  **    **        **  
        ******      ****      **  **    **      **  ********    ****    **    **  ******    ******    

        ********************************************************************************* -->
  <!-- beautify preserve:end -->

  <section id="downloads">
    <div class="py-12"></div>

    <v-container>
      <h2 class="display-2 font-weight-bold mb-3 text-center">{{this.$store.getters.conference.downloadsTitle}}</h2>

      <v-responsive class="mx-auto mb-12" width="56">
       
      </v-responsive>

      <v-row>
        <v-col v-for="({ title, thumb, link }, i) in publicDownloads" :key="i" cols="12" md="4">

          <a :href="link" target="_blank">
            <v-img :src="thumb" class="mb-4 align-center" height="275" max-width="100%" contain></v-img>
          </a>
          <h3 class="font-weight-black mb-4 text-uppercase text-center" v-text="title"></h3>

        </v-col>
      </v-row>
    </v-container>

    <v-responsive class="mx-auto mb-12" width="56">
     
    </v-responsive>

    

    <div class="py-12"></div>
  </section>

</template>

<script>
  import HostsOverview from '@/components/HostsOverview.vue';
  import { formatTime } from '../utils/dateutils';
  import axios from "../axios/basicAxios";
  import _ from 'lodash';

  export default {
    name: 'Downloads',

    components: {
      HostsOverview,
    },

    data: () => ({

      publicDownloads: [],
      conferences: [],
      conferencesLoaded: false,
      privateDownloadsDecrypted: "",
      lazyDownload: false,
      dialog: false,
      authenticated: false,
      password: "",
      passwordShow: false,
      downloadPanel: [],
      downloadSessionPanel: [],
      downloadEmail: '',
      emailRules: [
        v => !!v || 'E-mail ist ein Mussfeld',
        v => /.+@.+\..+/.test(v) || 'Bitte geben Sie eine korrekte Emailadresse ein',
      ],
      validDownload: false,
    }),
    async created () {
      await this.loadPublicDownloads();
    },
    methods: {

      reset() {
        this.$refs.form.reset()
      },
      resetValidation() {
        this.$refs.form.resetValidation()
      },

      async loadPublicDownloads() {
        this.$store.getters.conference.publicDownloads.forEach(cur => {
          this.publicDownloads.push({
            title: cur.title,
            thumb: this.buildPath(cur.thumb.url),
            link: cur.file ? this.buildPath(cur.file.url) : cur.link
          });
        });
      },

      async loadPrivateDownloads() {
        await this.loadConferences();
        await this.loadSessions(this.conferences[0]);
      },

      async loadConferences() {
        if (this.conferencesLoaded) {
          return;
        }

        const res = (await axios.get('/conferences/')).data;
        res.forEach(conf => {
          this.conferences.push({
              id: conf.id,
              title: conf.title,
              from: conf.from,
              logo: this.buildPath(conf.logo.url),
              sessions: [],
              sessionsLoaded: false
            });
        });

        // order conferences desc
        this.conferences = _.orderBy(this.conferences, 'from', 'desc');

        this.conferencesLoaded = true;
      },

      async loadSessions(conf) {
        if (conf.sessionsLoaded) {
          return;
        }

        const res = (await axios.get(`/restricted-sessions?session.conference.id=${conf.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        })).data;

        res.forEach(rs => {
          const session = rs.session;

          const sessionToAdd = {
            start: session.start,
            end: session.end,
            date: session.date,
            time: `${formatTime(session.start)} - ${formatTime(session.end)}`,
            colour: session.colour,
            text: session.title,
            hosts: [],
            videos: [],
            files: []
          };

          session.hosts.forEach(host => {
            const hostToAdd = {
              name: host.name,
              experte: host.title,
              cv: host.description,
              img_url: this.buildPath(host.picture.url),
              company_name: host.companyName,
              company_link: host.companyLink,
              social: [],
            };

            host.social.forEach(curSocial => {
              hostToAdd.social.push({
                name: curSocial.name,
                image: this.buildPath(curSocial.image.url),
                link: curSocial.link
              });
            });
            sessionToAdd.hosts.push(hostToAdd);
          });

          rs.videos.forEach(curVideo => {
            sessionToAdd.videos.push({
              title: curVideo.name,
              videoid: curVideo.youtubeId
            });
          });

          rs.files.forEach(curFile => {
            sessionToAdd.files.push({
              title: curFile.title,
              thumb: this.buildPath(curFile.thumb.url),
              link: curFile.file ? this.buildPath(curFile.file.url) : curFile.link
            });
          });

          conf.sessions.push(sessionToAdd);
        });

        // order sessions
        conf.sessions = _.sortBy(conf.sessions, ['date', 'start']);

        conf.sessionsLoaded = true;
      },

      async openPrivateDownloads() {
        if (localStorage.hasOwnProperty('token') && !this.conferencesLoaded) {
          try {
            this.authenticated = true;
            await this.loadPrivateDownloads();
            this.$nextTick(() => {
              this.expandLatest();
            });
          } catch (err) {
            this.authenticated = false;
            console.error(err);
            localStorage.removeItem('token');
          }
        }
      },

      async login() {
        let rest2MailData;

        try {
          const res = (await axios.post('/auth/local', {
            identifier: 'agilecircle@seqis.com',
            password: this.password,
          })).data;

          const token = res.jwt;
          if (token) {
            localStorage.setItem('token', token);
          }
    
          await this.loadPrivateDownloads();
          this.authenticated = true;

          rest2MailData = {
              //name: this.name,
              email: this.downloadEmail,
              subject: 'Erfolgreiche Anmeldung beim Downloadbereich',
              text: `Email: ${this.downloadEmail} \nhat sich erfolgreich beim privaten Downloadbereich angemeldet`
          };


          this.$nextTick(() => {
            this.expandLatest();
          });

        } catch (err) {
          this.authenticated = false;
          console.error(err);
          localStorage.removeItem('token');

          rest2MailData = {
              //name: this.name,
              email: this.downloadEmail,
              subject: 'Fehlgeschlagene Anmeldung beim Downloadbereich',
              text: `Email: ${this.downloadEmail} \nhat versucht sich mit einem falschen Passwort beim privaten Downloadbereich anzumelden`
            };
        }
        axios.post('/', rest2MailData, {baseURL: global.REST2MAIL_URL});
      },

      expandLatest() {
        // only expand latest conference
        this.downloadPanel = [0];

        // expand all sessions of latest conference
        this.downloadSessionPanel = [...Array(this.conferences[0].sessions.length).keys()]
     },
    },

    watch: {
      dialog: function(open) {
        this.$emit('dialogChanged', open);
      }
    }

  }
</script>