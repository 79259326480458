<template>
 <!-- beautify preserve:start -->
      <!-- ****************************************************************************

        ********    ****      ****    ******  ********  ******    
        **        **    **  **    **    **    **        **    **  
        ********  **    **  **    **    **    ********  ******    
        **        **    **  **    **    **    **        **  **    
        **          ****      ****      **    ********  **    **  

      ********************************************************************************* -->
      <!-- beautify preserve:end -->

      
<div>
  <social-media-bar color='#292929' class="pb-5"></social-media-bar>
  <vue-markdown class="title font-weight-light grey--text text--lighten-1 text-center">
    {{this.$store.getters.conference.legalNotice}}
  </vue-markdown>
</div>
</template>

<script>
import SocialMediaBar from '@/components/SocialMediaBar.vue';
import VueMarkdown from 'vue-markdown';

export default {
  name: "Footer",

  components: {
    SocialMediaBar,
    VueMarkdown,
  }
};
</script>