<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

          ******    ********  ******    ****    **  **          ******  
          **    **  **          **    **    **  **  **        **        
          **    **  ********    **    ********  **  **          ****    
          **    **  **          **    **    **  **  **              **  
          ******    ********    **    **    **  **  ********  ******    

  *********************************************************************************-->
  <!-- beautify preserve:end -->

  <section >
    <div class="py-12"></div>

    <v-container>
      <h2 class="display-2 font-weight-bold mb-3 text-center">{{this.$store.getters.conference.detailsTitle}}</h2>

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <v-row>
        <v-col
          v-for="({ text, title, imageurl, imageright}, i) in details"
          :key="i"
          cols="12"
          md="4"
        >
          <v-img :src="imageurl" height="275" max-width="100%"></v-img>
          <div class="caption font-regular mb-4" v-text="imageright"></div>

          <h3 class="font-weight-black mb-4 text-uppercase" v-text="title"></h3>

          <vue-markdown class="title font-weight-light mb-5">{{text}}</vue-markdown>

          <!--               <v-btn
                class="ml-n4 font-weight-black"
                text
              >
                Continue Reading
          </v-btn>-->
        </v-col>
      </v-row>

      <v-responsive class="mx-auto mb-12" width="56" id="retro">
      </v-responsive>

      <h2 class="display-2 font-weight-bold mb-3 text-center">Rückblicke</h2>

            <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <v-row>
        <v-col
          v-for="({ title, description, imageurl, videoid, programmurl}, i) in retrospectives"
          :key="i"
          cols="12"
          md="4"
        >
          <v-img :src="imageurl" height="275" max-width="100%"></v-img>
          <div class="caption font-regular mb-4" ></div>

          <h3 class="font-weight-black mb-4 text-uppercase" v-text="title"></h3>

          <vue-markdown class="title font-weight-light mb-5">{{description}}</vue-markdown>

                <div v-if="videoid" class="embed-responsive embed-responsive-16by9">
        <youtube host="https://www.youtube-nocookie.com" class="embed-responsive-item" player-width="84%"
          player-height="100%" :video-id="videoid">
        </youtube>
          </div>

          <DownloadsACO2020 v-if="title=='Rückblick AC ONLINE 2020'" @dialogChanged="privateDownloadsOpened = $event"></DownloadsACO2020>
          <DownloadsACO2021 v-if="title=='Rückblick AC ONLINE 2021'" @dialogChanged="privateDownloadsOpened = $event"></DownloadsACO2021>
          <DownloadsACO2021SUMMER v-if="title=='Rückblick AC ONLINE SUMMER 2021'" @dialogChanged="privateDownloadsOpened = $event"></DownloadsACO2021SUMMER>

          <!--               <v-btn
                class="ml-n4 font-weight-black"
                text
              >
                Continue Reading
          </v-btn>-->
        </v-col>
      </v-row>
    </v-container>

    <div class="py-12"></div>
  </section>
</template>

<script>
import VueMarkdown from 'vue-markdown'
import DownloadsACO2020 from '@/sections/DownloadsACO2020'
import DownloadsACO2021 from '@/sections/DownloadsACO2021'
import DownloadsACO2021SUMMER from '@/sections/DownloadsACO2021SUMMER'


export default {
  name: "Details",

  components: {
    VueMarkdown,
    DownloadsACO2020,
    DownloadsACO2021,
    DownloadsACO2021SUMMER


  },

  data: () => ({
    details: [],
    retrospectives: [],
  }),

  created () {
    this.loadDetails();
    this.loadRetrospectives();
  },

  methods: {
    loadDetails() {
      const details = this.$store.getters.conference.details;

      details.forEach(cur => {
          this.details.push({
            title: cur.title,
            text: cur.text,
            imageurl: this.buildPath(cur.image?.url),
            imageright: cur.imageright
          });
        });
    },
    loadRetrospectives() {
      const retrospectives = this.$store.getters.retrospectives;

      retrospectives.forEach(retrospective => {
          this.retrospectives.push({
            title: retrospective.title,
            description: retrospective.description,
            imageurl: this.buildPath(retrospective.image[0]?.url),
            videoid: retrospective.youtubeid,
            programurl: retrospective.programurl,
          });
        });
    },
  }
};
</script>