import moment from 'moment'

function formatTime(time) {
    return moment(time, 'HH:mm:ss').format('HH:mm');
}

function formatDate(date, withFullMonth = false) {
    return moment(date, 'YYYY-MM-DD').locale('de-AT').format(withFullMonth ? 'DD. MMMM YYYY' : 'DD.MM.YYYY');
}

function formatDateRange(from, to, withFullMonth = false) {
    if (to && from != to) {
        return `${formatDate(from, withFullMonth)} - ${formatDate(to, withFullMonth)}`;
    }
    return formatDate(from, withFullMonth);
}

export {
    formatTime,
    formatDate,
    formatDateRange,
}