<template>
  <div id="main">
    <Hero @go-to-teaser="$emit('go-to-teaser')" />
    <Teaser />

    <Features />
  <!-- <ProgrammParallelTracks /> -->
    <Logos />
    <Downloads @dialogChanged="privateDownloadsOpened = $event" />
    <!-- Workaround to fix "shine through" of parallax image when private downloads dialog is open -->
    <Stats :style="{visibility: privateDownloadsOpened ? 'hidden' : 'visible'}" />
    <About />
    <Details />
    <Team />
    <Contact />
  </div>
</template>

<script>
  import Hero from "@/sections/Hero";
  import Teaser from "@/sections/Teaser";

  import Features from "@/sections/Features";
  import ProgrammParallelTracks from "@/sections/ProgrammParallelTracks";
  import Logos from "@/sections/Logos";
  import Downloads from "@/sections/Downloads";
  import Stats from "@/sections/Stats";
  import About from "@/sections/About";
  import Details from "@/sections/Details";
  import Team from "@/sections/Team";
  import Contact from "@/sections/Contact";
  import DownloadsACO2021 from '@/sections/DownloadsACO2021.vue';

// ProgrammParallelTracks,
  export default {
    components: {
      Hero,
      Teaser,

      Features,
      ProgrammParallelTracks,
      Logos,
      Downloads,
      Stats,
      About,
      Details,
      Team,
      Contact,
      DownloadsACO2021,
    },
    
    data: () => ({
      privateDownloadsOpened: false,
    }),

    mounted() {
      const path = this.$route.path;
      if (path != '/') {
        setTimeout(() => this.$emit('goTo', path.replace('/', '#')), 800);
      }
    },
  };
</script>