<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

        ******      ****    **      **  **      **  **          ****      ****    ******      ******  
        **    **  **    **  **  **  **  ****    **  **        **    **  **    **  **    **  **        
        **    **  **    **  **  **  **  **  **  **  **        **    **  ********  **    **    ****    
        **    **  **    **  **  **  **  **    ****  **        **    **  **    **  **    **        **  
        ******      ****      **  **    **      **  ********    ****    **    **  ******    ******    

        ********************************************************************************* -->
  <!-- beautify preserve:end -->

  <section id="downloadsACO2021SUMMER">
    <div class="py-12"></div>

    <v-responsive class="mx-auto mb-12" width="56">
     
    </v-responsive>

    <v-row justify="center">
      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">


        <template v-slot:activator="{ on }">
          <v-btn @click="openPrivateDownloads()" color="#c00000" raised x-large v-on="on">
            <span class="white--text text--darken-1 font-weight-bold">
              Downloads für Teilnehmer
            </span>
          </v-btn>
        </template>


        <v-toolbar light color="#C00000">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">Geschützter Downloadbereich zum Agile Circle ONLINE SUMMER 2021
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn light color="white" text @click="dialog = false">Schliessen</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card justify="center">
          <v-row v-if="!authenticated">
            <v-col cols="4"></v-col>
            <v-col cols="4">
              <v-card flat class="mt-6 mb-6">
                <v-form ref="formDownload" v-model="validDownload" :lazy-validation="lazyDownload">
                  <div class="text-center">Für den geschützten Bereich benötigen Sie Ihre Emailadresse und ihr
                    Download-Passwort, das
                    Sie nach
                    der Konferenz zugeschickt bekommen haben. Sollten Sie Ihr Passwort vergessen haben, wenden Sie
                    sich
                    bitte per Mail an <a href="mailto:konferenz@agilecircle.org">konferenz@agilecircle.org</a>.
                  </div>

                  <v-text-field v-model="downloadEmail" :rules="emailRules" label="E-mail" required>
                  </v-text-field>

                  <v-text-field v-model="password" :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="passwordShow ? 'text' : 'password'" name="input-10-1" label="Passwort" counter
                    @click:append="passwordShow = !passwordShow"></v-text-field>

                  <div class="d-flex justify-center">
                    <v-btn color="#c00000" raised x-large @click="login()" :disabled="!validDownload">
                      <span class="white--text text--darken-1 font-weight-bold">
                        Anmelden
                      </span>
                    </v-btn>
                  </div>
                </v-form>

              </v-card>
            </v-col>
            <v-col cols="4"></v-col>
          </v-row>
          <v-row v-else justify="center">
            <section id="agilecircle-downloads">
              <div class="py-12"></div>

              <v-container v-if="conferencesLoaded">

                <v-expansion-panels :value="downloadPanel" multiple>

                  <v-expansion-panel v-for="conf of conferences" :key="conf.id">

                    <v-expansion-panel-header @click="loadSessions(conf)" class="pa-1">
                      <div class="d-flex flex-column flex-sm-row">
                        <v-row no-gutters align="center" justify="start"
                          class="my-auto d-block d-sm-inline flex-grow-0 flex-shrink-1">
                          <v-col class="pa-1" cols="auto" align="center" justify="center">
                            <v-img :src="conf.logo"></v-img>
                          </v-col>
                        </v-row>
                        <v-row class="" no-gutters align="center" justify="center">
                          <v-col cols="12" class="pa-5">
                            <h3 class="headline font-weight-bold text-right text-sm-right" v-text="conf.title">
                            </h3>
                          </v-col>
                        </v-row>
                      </div>

                    </v-expansion-panel-header>

                    <v-expansion-panel-content v-if="conf.sessionsLoaded">
                      <v-expansion-panels :value="downloadSessionPanel" multiple>
                        <v-expansion-panel v-for="({
                              time, 
                              colour, 
                              text,
                              hosts, 
                              videos,
                              files}, keySessions) of conf.sessions" :key="keySessions">
                          <v-expansion-panel-header class="pa-1">
                            <div class="d-flex flex-column flex-sm-row">
                              <v-row no-gutters align="center" justify="start"
                                class="my-auto d-block d-sm-inline flex-grow-0 flex-shrink-1">
                                <v-col class="pa-1" cols="auto" align="center" justify="center">
                                  <v-chip style="min-width: 60px;" class="ma-0 font-weight-black" x-large text-color="white"
                                    :color="colour" v-text="time">
                                  </v-chip>
                                </v-col>
                              </v-row>
                              
                              <v-row class="d-block d-sm-inline" no-gutters align="center" justify="start">
                                <v-col cols="12" class="pa-5">
                                  <h3 class="title font-weight-bold text-center text-sm-left" v-text="text"></h3>
                                </v-col>
                              </v-row>
                            </div>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content align="center">
                             <div class="py-6"></div>

                            <v-row v-for="({ videoid }, i) in videos" :key="i" justify="center" align="center">
                              <v-col class="embed-responsive embed-responsive-16by9" cols="12" justify="center"
                                align="center">

                                <youtube host="https://www.youtube-nocookie.com" class="embed-responsive-item"
                                  player-width="100%" player-height="100%" :video-id="videoid"></youtube>


                              </v-col>
                            </v-row>

                            <v-row>
                              <v-col v-for="({ title, thumb, link }, i) in files" :key="i" cols="12" md="4">

                                <a :href="link" target="_blank">
                                  <v-img :src="thumb" class="mb-4 align-center" height="275" max-width="100%" contain>
                                  </v-img>
                                </a>
                                <h3 class="font-weight-black mb-4 text-uppercase text-center" v-text="title"></h3>

                              </v-col>
                            </v-row>

                            <section>
                             <hosts-overview :hosts="hosts"></hosts-overview>
                            </section>

                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else align="center">
                      <v-progress-circular indeterminate></v-progress-circular>
                    </v-expansion-panel-content>

                  </v-expansion-panel>
                </v-expansion-panels>

              </v-container>
              <v-container v-else align="center">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-container>
              <div class="py-8"></div>
            </section>
          </v-row>
        </v-card>

      </v-dialog>
    </v-row>

    <div class="py-12"></div>
  </section>

</template>

<script>
  import HostsOverview from '@/components/HostsOverview.vue';
  import { formatTime } from '../utils/dateutils';
  import axios from "../axios/basicAxios";
  import _ from 'lodash';

  export default {
    name: 'DownloadsACO2021SUMMER',

    components: {
      HostsOverview,
    },

    data: () => ({

      publicDownloads: [],
      conferences: [],
      conferencesLoaded: false,
      privateDownloadsDecrypted: "",
      lazyDownload: false,
      dialog: false,
      authenticated: false,
      password: "",
      passwordShow: false,
      downloadPanel: [],
      downloadSessionPanel: [],
      downloadEmail: '',
      emailRules: [
        v => !!v || 'E-mail ist ein Mussfeld',
        v => /.+@.+\..+/.test(v) || 'Bitte geben Sie eine korrekte Emailadresse ein',
      ],
      validDownload: false,
    }),
    async created () {
      await this.loadPublicDownloads();
    },
    methods: {

      reset() {
        this.$refs.form.reset()
      },
      resetValidation() {
        this.$refs.form.resetValidation()
      },

      async loadPublicDownloads() {
        this.$store.getters.conference.publicDownloads.forEach(cur => {
          this.publicDownloads.push({
            title: cur.title,
            thumb: this.buildPath(cur.thumb.url),
            link: cur.file ? this.buildPath(cur.file.url) : cur.link
          });
        });
      },

      async loadPrivateDownloads() {
        await this.loadConferences();
        await this.loadSessions(this.conferences[0]);
      },

      async loadConferences() {
        if (this.conferencesLoaded) {
          return;
        }

        const conf = (await axios.get('/conferences/3')).data; // just load conference with id 3, which is AC ONLINE 2021 SUMMER (dont think about it ;-)
        //res.forEach(conf => {
          this.conferences.push({
              id: conf.id,
              title: conf.title,
              from: conf.from,
              logo: this.buildPath(conf.logo.url),
              sessions: [],
              sessionsLoaded: false
            });
        //});

        // order conferences desc
        this.conferences = _.orderBy(this.conferences, 'from', 'desc');

        this.conferencesLoaded = true;
      },

      async loadSessions(conf) {
        if (conf.sessionsLoaded) {
          return;
        }

        const res = (await axios.get(`/restricted-sessions?session.conference.id=${conf.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          }
        })).data;

        res.forEach(rs => {
          const session = rs.session;

          const sessionToAdd = {
            start: session.start,
            end: session.end,
            date: session.date,
            time: `${formatTime(session.start)} - ${formatTime(session.end)}`,
            colour: session.colour,
            text: session.title,
            hosts: [],
            videos: [],
            files: []
          };

          session.hosts.forEach(host => {
            const hostToAdd = {
              name: host.name,
              experte: host.title,
              cv: host.description,
              img_url: this.buildPath(host.picture.url),
              company_name: host.companyName,
              company_link: host.companyLink,
              social: [],
            };

            host.social.forEach(curSocial => {
              hostToAdd.social.push({
                name: curSocial.name,
                image: this.buildPath(curSocial.image.url),
                link: curSocial.link
              });
            });
            sessionToAdd.hosts.push(hostToAdd);
          });

          rs.videos.forEach(curVideo => {
            sessionToAdd.videos.push({
              title: curVideo.name,
              videoid: curVideo.youtubeId
            });
          });

          rs.files.forEach(curFile => {
            sessionToAdd.files.push({
              title: curFile.title,
              thumb: this.buildPath(curFile.thumb?.url),
              link: curFile.file ? this.buildPath(curFile.file.url) : curFile.link
            });
          });

          conf.sessions.push(sessionToAdd);
        });

        // order sessions
        conf.sessions = _.sortBy(conf.sessions, ['date', 'start']);

        conf.sessionsLoaded = true;
      },

      async openPrivateDownloads() {
        if (localStorage.hasOwnProperty('token') && !this.conferencesLoaded) {
          try {
            this.authenticated = true;
            await this.loadPrivateDownloads();
            this.$nextTick(() => {
              this.expandLatest();
            });
          } catch (err) {
            this.authenticated = false;
            console.error(err);
            localStorage.removeItem('token');
          }
        }
      },

      async login() {
        let rest2MailData;

        try {
          const res = (await axios.post('/auth/local', {
            identifier: 'agilecircle@seqis.com',
            password: this.password,
          })).data;

          const token = res.jwt;
          if (token) {
            localStorage.setItem('token', token);
          }
    
          await this.loadPrivateDownloads();
          this.authenticated = true;

          rest2MailData = {
              //name: this.name,
              email: this.downloadEmail,
              subject: 'Erfolgreiche Anmeldung beim Downloadbereich AC ONLINE SUMMER 2021',
              text: `Email: ${this.downloadEmail} \nhat sich erfolgreich beim privaten Downloadbereich angemeldet`
          };


          this.$nextTick(() => {
            this.expandLatest();
          });

        } catch (err) {
          this.authenticated = false;
          console.error(err);
          localStorage.removeItem('token');

          rest2MailData = {
              //name: this.name,
              email: this.downloadEmail,
              subject: 'Fehlgeschlagene Anmeldung beim Downloadbereich AC ONLINE SUMMER 2021',
              text: `Email: ${this.downloadEmail} \nhat versucht sich mit einem falschen Passwort beim privaten Downloadbereich anzumelden`
            };
        }
        axios.post('/', rest2MailData, {baseURL: global.REST2MAIL_URL});
      },

      expandLatest() {
        // only expand latest conference
        this.downloadPanel = [0];

        // expand all sessions of latest conference
        this.downloadSessionPanel = [...Array(this.conferences[0].sessions.length).keys()]
     },
    },

    watch: {
      dialog: function(open) {
        this.$emit('dialogChanged', open);
      }
    }

  }
</script>