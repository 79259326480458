<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

            ********  ********    ****    ******  **    **  ******    ********    ******  
            **        **        **    **    **    **    **  **    **  **        **        
            ********  ********  ********    **    **    **  ******    ********    ****    
            **        **        **    **    **    **    **  **  **    **              **  
            **        ********  **    **    **      ****    **    **  ********  ******    

         ********************************************************************************* -->
  <!-- beautify preserve:end -->


  <section id="tickets" class="grey lighten-3">
    <div class="py-12"></div>

    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3">{{featuresHeader}}</h2>
      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <!--<h3-- class="font-weight-light">Für die Community: Alle Einnahmen des Agile Circle dienen der Community Pflege. Die Ticketpreise wurden so kalkuliert, dass damit Aufgaben für Events und Infrastruktur etc. gedeckt sein sollten. Überschüssigen Erlöse werden für den weiteren Ausbau des Agile Circle verwendet, die Mitarbeit im Agile Circle ist ehrenamtlich.</h3-->

      <signup></signup>

     <!-- <book-ticket-button></book-ticket-button> -->

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <!--h3-- class="font-weight-light">Ticketpreis für Konferenz und Party € 49,00. Preise für die Workshops folgen nach Fixierung des Programmes.</!--h3-->

      
      <!--h3 class="font-weight-light">Bitte beachten Sie auch, dass für uns als Veranstalter das Wohl unserer Teilnehmerinnen und Teilnehmer, Referentinnen und Referenten an oberster Stelle steht, daher wird die Conference selbstverständlich unter den gesetzlichen Corona-Maßnahmen stattfinden.</!--h3-->


      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <feature-row :features="featuresTop"></feature-row>

      <v-responsive class="mx-auto mb-12" width="56">


      </v-responsive>

      <div v-if="agendaVideo">
        <div v-if="agendaVideo.title">
          <h2 class="display-2 font-weight-bold mb-3">{{agendaVideo.title}}</h2>
          <v-responsive class="mx-auto mb-12" width="56">
          </v-responsive>
        </div>
        <div v-if="agendaVideo.youtubeId" class="embed-responsive embed-responsive-16by9">
          <youtube host="https://www.youtube-nocookie.com" class="embed-responsive-item" player-width="84%"
            player-height="100%" :video-id="agendaVideo.youtubeId">
          </youtube>
        </div>


        <v-responsive class="mx-auto mb-4 mt-4" width="56">
        </v-responsive>
      </div>


      <feature-row :features="featuresBottom"></feature-row>

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <div v-if="detailVideo">
        <div v-if="detailVideo.title">
          <h2 class="display-2 font-weight-bold mb-3">{{detailVideo.title}}</h2>

          <v-responsive class="mx-auto mb-12" width="56">
          </v-responsive>
        </div>

        <div v-if="detailVideo.youtubeId" class="embed-responsive embed-responsive-16by9">
          <youtube host="https://www.youtube-nocookie.com" class="embed-responsive-item" player-width="84%"
            player-height="100%" :video-id="detailVideo.youtubeId">
          </youtube>
        </div>


        <v-responsive class="mx-auto mb-4 mt-4" width="56">
        </v-responsive>
      </div>


    </v-container>



    <div class="py-12"></div>
  </section>
</template>

<script>
  import BookTicketButton from '@/components/BookTicketButton.vue';
  import FeatureRow from '@/components/FeatureRow.vue';
  import Signup from '@/sections/Signup.vue'
  

  export default {
    name: 'Features',
    components: {
      BookTicketButton,
      FeatureRow,
      Signup,
    },

    data: () => ({
      featuresHeader: "",
      featuresTop: [],
      featuresBottom: [],
      agendaVideo: undefined,
      detailVideo: undefined,
    }),

    created() {
      this.buildFeatures();
    },

    methods: {
      buildFeatures() {
        const conf = this.$store.getters.conference;

        this.featuresHeader = conf.featuresHeader;
        this.agendaVideo = conf.agendaVideo;
        this.detailVideo = conf.detailVideo;

        this.featuresTop = this.buildFeatureArray(conf.featuresTop);
        this.featuresBottom = this.buildFeatureArray(conf.featuresBottom);
      },

      buildFeatureArray(features) {
        let array = [];

        features.forEach(feature => {
          array.push({
            icon: this.buildPath(feature.icon.url),
            title: feature.title,
            text: feature.text,
            videoid: feature.youtubeId,
          });
        });
        return array;
      }
    }
  }
</script>