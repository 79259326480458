<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

          ******    ******      ****      ******  ******      ****    **      **  
          **    **  **    **  **    **  **        **    **  **    **  ****  ****  
          ******    ******    **    **  **  ****  ******    ********  **  **  **  
          **        **  **    **    **  **    **  **  **    **    **  **      **  
          **        **    **    ****      ****    **    **  **    **  **      **  

        ********************************************************************************* -->
  <!-- beautify preserve:end -->

  <section id="program">
    <v-container>

      <div class="py-12"></div>

      <h2 class="display-2 font-weight-bold mb-3 text-center">{{header}}
      </h2>

      <v-card class="mx-auto" max-width="1200">
        <v-card light flat>
          <v-card-title class="pa-2 lighten-3" color="#C00000">

          </v-card-title>
          <v-img :src="this.buildPath(this.$store.getters.conference.coverImage.url)" max-height=300 position="top">



            <v-speed-dial v-if="enableSharingButtons" v-model="dialShare" direction="top" absolute right bottom open-on-hover>
              <template v-slot:activator>
                <v-btn fab elevation="0" bottom small color="#C00000">
                  <v-icon v-if="dialShare" color="#ffffff">mdi-close</v-icon>
                  <v-icon color="#ffffff" v-else>mdi-share-variant</v-icon>
                </v-btn>
              </template>
              <v-btn dark fab elevation="0" bottom color="blue darken-7" small
                :href="`https://www.linkedin.com/shareArticle?mini=true&url=https://www.agilecircle.org${this.$route.fullPath}`"
                target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
              <v-btn dark fab elevation="0" bottom color="blue" small
                :href="`https://www.facebook.com/sharer/sharer.php?u=https://www.agilecircle.org${this.$route.fullPath}`"
                target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
              <v-btn dark fab elevation="0" bottom color="green" small
                :href="`https://wa.me/?text=Schau%20Dir%20dieses%20Konferenzprogramm%20an: https://www.agilecircle.org${this.$route.fullPath}`"
                target="_blank">
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
              <v-btn dark fab elevation="0" bottom color="tertiary" small
                :href="`mailto:?subject=Agile%20Circle%20ONLINE%2028.01.2021!&amp;body=Schau%20Dir%20dieses%20Konferenzprogramm%20an!<a href='https://www.agilecircle.org${this.$route.fullPath}'>https://www.agilecircle.org${this.$route.fullPath}</a>`"
                target="_blank">
                <v-icon>mdi-email</v-icon>
              </v-btn>
            </v-speed-dial>

          </v-img>




        </v-card>
        <v-card-text class="py-0">
          <v-timeline align-top dense>
            <v-timeline-item v-for="(track, trackKey) in programParallelTracks" :key="trackKey" color="#C00000" small>
              <v-row class="pt-1">
                <v-col cols="3" min-width="70px">
                  <strong
                    class="d-flex flex-grow-1 flex-column flex-wrap flex-md-nowrap flex-md-row title font-weight-bold">{{track.start}}
                    - {{track.end}}</strong>
                </v-col>
                <v-col>
                  <v-card class="d-flex flex-grow-1 flex-column flex-wrap flex-md-nowrap flex-md-row" light flat>

                    <!-- class=" mx-auto " :max-width="width" max-height="99%" -->
                    <v-card
                      v-for="({id, text, abstract, stage, hosts, colour, trackName}, sessionKey) in track.sessions"
                      :key="sessionKey" :width="track.sessions.length!=1 ? width:'100%'" outlined min-height="200px"
                      class="mr-2 mb-2 d-flex flex-column">
                      <v-list-item three-line>
                        <v-list-item-avatar v-if="hosts.length <= 2" rounded="circle" size="80">
                          <v-img class="avatar" :src="hosts[0].img_url"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-avatar v-if="hosts[1] != undefined && hosts.length <= 2" rounded="circle" size="80">
                          <v-img class="avatar" :src="hosts[1].img_url"></v-img>
                        </v-list-item-avatar>
                        <v-list-item-content>


        <v-row v-if="hosts.length > 2" class="d-flex flex-column flex-wrap flex-sm-row">
          <v-col v-for="(host, i) in hosts" :key="i" align="center" justify="center" cols="4" >

            <v-avatar v-if="host.img_url" class="elevation-12 mb-6" size="80">
              <v-img class="avatar" :src="host.img_url"></v-img>
            </v-avatar>
            <div class="overline font-weight-bold mb-1" v-text="host.name"></div>

            

          </v-col>
        </v-row>

                          <div v-if="hosts.length <= 2" class="overline mb-4 ml-1">
                            {{hosts[0].name}}
                          </div>
                          <div v-if="hosts[1] != undefined && hosts.length <= 2" class="overline mb-4 ml-1">
                            {{hosts[1].name}}
                          </div>
                          <v-list-item-title class="title mb-1  text-wrap">
                            {{text}}
                          </v-list-item-title>
                          <v-list-item-subtitle class="text-wrap">{{abstract}}</v-list-item-subtitle>
                        </v-list-item-content>


                      </v-list-item>
                      <v-spacer></v-spacer>
                      <v-card-actions class="d-flex align-end">
                        <v-chip v-if="trackName" :color="colour" text-color="white" class="ma-1">
                          {{trackName}}
                        </v-chip>

                        <v-chip v-if="stage" color="#bababa" text-color="white" class="ma-1">
                          {{stage}}
                        </v-chip>

                        <v-spacer></v-spacer>

                        <v-btn color="#c00000" text @click="navigateToSessionDetail(id)">
                          <span class=" font-weight-bold">
                            Details
                          </span>
                        </v-btn>

                      </v-card-actions>
                    </v-card>

                  </v-card>
                </v-col>
              </v-row>
            </v-timeline-item>
          </v-timeline>

          <v-dialog v-model="dialog" hide-overlay transition="dialog-bottom-transition" max-width="1000px"
            :key="actualSession" eager>
            <v-toolbar light color="#C00000">
              <v-btn icon dark @click="dialog=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title class="white--text">Session Details
              </v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-card justify="center">
              <v-row justify="center">
                <section id="agilecircle-session">
                  <div class="py-12"></div>

                  <v-row v-if="sessionDetail.videoid" justify="center" align="center">
                    <v-col class="embed-responsive embed-responsive-16by9" cols="10" justify="center" align="center">

                      <youtube host="https://www.youtube-nocookie.com" class="embed-responsive-item" player-width="100%"
                        player-height="100%" :video-id="sessionDetail.videoid"></youtube>

                    </v-col>
                  </v-row>
                  <v-row justify="center">
                    <v-spacer></v-spacer>
                    <v-col cols="auto" justify="center" class="d-flex flex-row">
                      <v-chip v-if="sessionDetail.trackName" :color="sessionDetail.colour" text-color="white"
                        text-center class="ma-1 text-center">
                        {{sessionDetail.trackName}}
                      </v-chip>

                      <v-chip v-if="sessionDetail.stage" color="#bababa" text-color="white" text-center
                        class="ma-1 text-center">
                        {{sessionDetail.stage}}
                      </v-chip>


                      <v-speed-dial v-if="enableSharingButtons" v-model="dialShareInDialog" direction="right" open-on-hover>
                        <template v-slot:activator>
                          <v-btn fab elevation="0" bottom small color="#C00000" class="ml-2 ">
                            <v-icon v-if="dialShare" color="#ffffff">mdi-close</v-icon>
                            <v-icon v-else color="#ffffff">mdi-share-variant</v-icon>
                          </v-btn>
                        </template>
                        <v-btn dark fab elevation="0" bottom color="blue darken-7" small
                          :href="`https://www.linkedin.com/shareArticle?mini=true&url=https://www.agilecircle.org${this.$route.fullPath}`"
                          target="_blank">
                          <v-icon>mdi-linkedin</v-icon>
                        </v-btn>
                        <v-btn dark fab elevation="0" bottom color="blue" small
                          :href="`https://www.facebook.com/sharer/sharer.php?u=https://www.agilecircle.org${this.$route.fullPath}`"
                          target="_blank">
                          <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                        <v-btn dark fab elevation="0" bottom color="green" small
                          :href="`https://wa.me/?text=Schau%20Dir%20diese%20Session%20an: https://www.agilecircle.org${this.$route.fullPath}`"
                          target="_blank">
                          <v-icon>mdi-whatsapp</v-icon>
                        </v-btn>
                        <v-btn dark fab elevation="0" bottom color="tertiary" small
                          :href="`mailto:?subject=Agile%20Circle%20ONLINE%2028.01.2021!&amp;body=Schau%20Dir%20diese%20Session%20an!<a href='https://www.agilecircle.org${this.$route.fullPath}'>https://www.agilecircle.org${this.$route.fullPath}</a>`"
                          target="_blank">
                          <v-icon>mdi-email</v-icon>
                        </v-btn>
                      </v-speed-dial>


                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>

                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="10" align="center" justify="center" max-width="800px">
                      <vue-markdown v-if="sessionDetail.text" id="title"
                        class="headline font-weight-bold mb-5 text-center">
                        {{sessionDetail.text}}
                      </vue-markdown>
                      <vue-markdown id="abstract" class="title font-weight-light mb-5 text-center">
                        {{sessionDetail.abstract}}
                      </vue-markdown>
                    </v-col>
                    <v-spacer></v-spacer>
                  </v-row>


                  <hosts-overview :hosts="sessionDetail.hosts"></hosts-overview>


                  <div class="py-8"></div>
                </section>
              </v-row>
            </v-card>
          </v-dialog>
        </v-card-text>
      </v-card>


      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>

      <v-row v-if="programFile" align="center" justify="center">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn color="#c00000" target="_blank" :href="programFile" raised x-large v-on="on">
              <span class="white--text text--darken-1 font-weight-bold">
                Programm herunterladen
              </span>
            </v-btn>
          </template>
          <span>Das Programm-PDF wird in einem neuen Fenster geöffnet...</span>
        </v-tooltip>
      </v-row>



      

      <v-row v-if="false" align="center" justify="center">
        <book-ticket-button></book-ticket-button>
      </v-row>

      <v-responsive class="mx-auto mb-12" width="56">
      </v-responsive>
      


      <div class="py-8"></div>
    </v-container>
  </section>
</template>

<script>
  import BookTicketButton from '@/components/BookTicketButton.vue';
  import HostsOverview from '@/components/HostsOverview.vue';

  import VueMarkdown from 'vue-markdown';
  import {
    formatTime,
    formatDateRange
  } from '../utils/dateutils';
  import _ from 'lodash';



  export default {
    name: 'ProgrammParallelTracks',
    components: {
      BookTicketButton,
      HostsOverview,
      VueMarkdown,

    },

    data: () => ({
      header: "",
      programParallelTracks: [],
      programFile: undefined,
      sessionDetail: {},
      allSessions: [],
      actualSession: "",
      dialog: false,
      dialShare: false,
      dialShareInDialog: false,
      enableSharingButtons: false,

    }),
    created() {
      this.loadProgramParallelTracks();
    },

    mounted() {

      if (this.$route.query.session != undefined) {
        this.showSessionDetail(this.$route.query.session);
      } else {
        this.dialog = false;
      }

    },

    computed: {
      width() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
            return "100%"
          case 'sm':
            return "100%"
          case 'md':
            return "49%"
          case 'lg':
            return "49%"
          case 'xl':
            return "49%"
        }
      },
    },
    watch: {

      dialog(val) {
        if (val == false) {
          this.$router.push({
            path: '#program'
          });
        }
      },

      $route(val) {
        if (val.query.session == undefined) {

          this.dialog = false;
        }
      }

    },

    methods: {

      navigateToSessionDetail(sessionIdToShow) {
        this.$router.push({
          path: '#program',
          query: {
            session: sessionIdToShow
          }
        });
        this.showSessionDetail(sessionIdToShow);
      },

      showSessionDetail(sessionIdToShow) {

        this.sessionDetail = this.allSessions[sessionIdToShow];

        this.actualSession = sessionIdToShow;

        this.$forceUpdate();

        this.dialog = true;

      },
      loadProgramParallelTracks() {
        const conf = this.$store.getters.conference;

        this.enableSharingButtons = conf.enableSharingButtons;

        // Build header
        this.header = `Programm ${conf.title} am ${formatDateRange(conf.from, conf.to)}`;

        this.programFile = this.buildPath(conf.programFile?.url);

        // Build program
        const sessionsParallelTracks = conf.sessionsParallelTracks;
        sessionsParallelTracks.forEach(sessionsParallelTrack => {

          const trackToAdd = {

            start: `${formatTime(sessionsParallelTrack.Start)}`,
            end: `${formatTime(sessionsParallelTrack.end)}`,
            date: sessionsParallelTrack.date,
            time: `${formatTime(sessionsParallelTrack.Start)} - ${formatTime(sessionsParallelTrack.end)}`,
            sessions: [],
            colour: "#C00000",

          };

          sessionsParallelTrack.sessions.forEach(session => {



            const sessionToAdd = {
              id: session.id,
              start: sessionsParallelTrack.Start,
              end: sessionsParallelTrack.end,
              date: sessionsParallelTrack.date,
              time: `${formatTime(sessionsParallelTrack.Start)} - ${formatTime(sessionsParallelTrack.end)}`,
              colour: session.colour,
              text: session.title,
              videoid: session.teasers[0]?.youtubeId,
              disabled: session.disabled,
              abstract: session.description,
              stage: session.stage4session.name,
              image: session.mainHostImage,
              trackName: session.track,
              hosts: [],
              dialog: false,
              sessionURI: encodeURI(session.text),
            };


            session.hosts.forEach(host => {
              const hostToAdd = {
                name: host.name,
                experte: host.title,
                cv: host.description,
                img_url: this.buildPath(host.picture.url),
                company_name: host.companyName,
                company_link: host.companyLink,
                social: [],
              };

              host.social.forEach(curSocial => {
                hostToAdd.social.push({
                  name: curSocial.name,
                  image: this.buildPath(curSocial.image.url),
                  link: curSocial.link
                });
              });
              sessionToAdd.hosts.push(hostToAdd);
            });
            
            sessionToAdd.hosts = _.sortBy(sessionToAdd.hosts, ['experte']);

            trackToAdd.sessions.push(sessionToAdd);
            this.allSessions[sessionToAdd.id] = sessionToAdd;




          });
          this.programParallelTracks.push(trackToAdd);
        });
        this.programParallelTracks = _.sortBy(this.programParallelTracks, ['date', 'start']);
      }
    }
  }
</script>
<style lang="scss">
  #program #abstract h3 {
    margin-bottom: 12px;
  }

  .avatar {
    filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1); /* Microsoft Edge and Firefox 35+ */

  }

  .avatar:hover {
  -webkit-filter: grayscale(0);
  filter: none;
}
</style>