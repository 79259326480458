<template>
  <!-- beautify preserve:start -->
  <!-- ****************************************************************************

            ******  ******    ****    ******    ******  
          **          **    **    **    **    **        
            ****      **    ********    **      ****    
                **    **    **    **    **          **  
          ******      **    **    **    **    ******    

  *********************************************************************************-->
  <!-- beautify preserve:end -->

  <section id="stats">
    <v-parallax :height="$vuetify.breakpoint.smAndDown ? 700 : 500" :src="statsImage">
      <v-container fill-height>
        <v-row class="mx-auto">
          <v-col v-for="entry of stats" :key="entry.name" cols="12" md="3">
            <div class="text-center">
              <div class="display-3 font-weight-black mb-4" v-text="entry.value"></div>

              <div class="title font-weight-regular text-uppercase" v-text="entry.name"></div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-parallax>
  </section>
</template>

<script>
export default {
  name: "Stats",

  data: () => ({
    statsImage: undefined,
    stats: [],
  }),
  
  created() {
    this.buildStats();
  },

  methods: {
    buildStats() {
      const conf = this.$store.getters.conference;

      this.statsImage = this.buildPath(conf.statsImage.url);
      this.stats = conf.stats;
    }
  }
};
</script>